const { $ } = window;

export default function resetErrorStylingOnKeypress() {
  this.each((_i, element) => {
    const jqElement = $(element);
    jqElement.find('input').on('keypress', () => {
      jqElement.find('.help-inline').remove();
      jqElement.removeClass('has-error');
    });
  });
}
