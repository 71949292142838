const { $ } = window;

function updateInternalLeadAvailability() {
  $.get('/admin/internal_lead_queue/current_status', (data) => {
    const el = $('.current-user-available-for-leads');
    if (data.available_for_leads) {
      el.removeClass('off').addClass('on');
    } else {
      el.removeClass('on').addClass('off');
    }
  });
}

export default function checkInternalLeadAvailability() {
  this.each(() => setInterval(updateInternalLeadAvailability, 30 * 1000));
}
