const { $ } = window;

export default function profileLookupForIvrCampaign() {
  return this.each((_i, element) => {
    $(element).on('change', (evt) => {
      const sender = $(evt.currentTarget);
      $.get(
        sender.data('profile-lookup-url'),
        {
          profile_id: sender.val()
        },
        (data) => {
          $(sender.data('account-id-selector')).val(data.account_id);
          $(sender.data('call-value-selector')).val(data.call_price);
          if ($('#call_source_incoming_number').val() !== data.phone_number) {
            $(sender.data('phone-number-selector')).val(data.phone_number);
          }
        }
      );
    });
  });
}
