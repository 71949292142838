const { $ } = window;

function testimonialAccountInfoBad() {
  $('#testimonal_account_info').removeClass('hidden');
  $('.testimonal_output').addClass('hidden');
}

function testimonialAccountInfoGood() {
  $('#testimonal_account_info').addClass('hidden');
  $('.testimonal_output').removeClass('hidden');
}

function accountAndCategoryInfo(accountFieldValue) {
  return $.ajax({
    type: 'GET',
    url: `${document.location.origin}/admin/testimonials/associated_account/${accountFieldValue}`
  }).done((response) => {
    if (response.status === 404) {
      $('#testimonal_account_info').html(response.html);
      testimonialAccountInfoBad();
    } else {
      $('.testimonal_output').html(response.html);
      testimonialAccountInfoGood();
    }
  });
}

export default function testimonial() {
  return this.each((_i, element) => {
    $(element).bind('input propertychange', () => {
      const accountFieldValue = $(this).val();
      if (accountFieldValue !== '') {
        accountAndCategoryInfo(accountFieldValue);
      } else if (accountFieldValue === '') {
        testimonialAccountInfoBad();
      }
    });
  });
}
