const { $ } = window;

class DateRange {
  constructor(element) {
    this.element = element;
    this.updateDateValues = this.updateDateValues.bind(this);
    this.perform = this.perform.bind(this);
    this.element = $(element);
    this.fromTo = this.element.find('.from_to');
    this.dateRangeInput = this.element.find(
      'select[name="search[advanced][date_range]"]'
    );
    this.url = `/admin/accounts/${this.element.data('id')}/session_date`;
    this.preselected = this.element.data('preselected');
    this.initialize();
  }

  initialize() {
    this.element.on('change', this.perform);
    if (this.preselected !== '') {
      this.fromTo.hide();
    }
  }

  perform() {
    this.showFromToIfDateRangeIsBlank();
    this.postDateAndRangeWithParams({
      date_range: this.element.find('#search_advanced_date_range').val(),
      from_date: this.element.find('#search_advanced_from_date').val(),
      to_date: this.element.find('#search_advanced_to_date').val()
    });
    return false;
  }

  postDateAndRangeWithParams(params) {
    $.post(
      this.url,
      {
        search: {
          advanced: params
        }
      },
      this.updateDateValues
    );
  }

  updateDateValues(data) {
    this.element.find('#search_advanced_date_range').val(data.date_range);
    this.element.find('#search_advanced_from_date').val(data.from_date);
    this.element.find('#search_advanced_to_date').val(data.to_date);
    this.showFromToIfDateRangeIsBlank();
  }

  showFromToIfDateRangeIsBlank() {
    if (this.dateRangeInput.val() === '') {
      this.fromTo.show();
    } else {
      this.fromTo.hide();
      this.fromTo.find('input').val('');
    }
  }
}

export default function sessionDates() {
  return this.each((_i, element) => {
    new DateRange(element);
  });
}
