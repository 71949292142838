const { $ } = window;

export default function expandThenDisable() {
  return this.each((_i, element) => {
    const $element = $(element);
    const $disabledElement = $($element.data('target'));
    const $clickableElement = $($element.data('click-target'));

    $element.on('change', () => {
      const isDisabled = !$element.prop('checked');

      if ($disabledElement != null) {
        $disabledElement.prop('disabled', isDisabled);
      }

      if ($clickableElement != null) {
        $clickableElement.trigger('click');
      }
    });
  });
}
