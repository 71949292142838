import { defaults, includes } from 'lodash';

const { $ } = window;
const DEFAULTS = {
  keycodes: [13]
};

export default function clickOnKeyPress(pluginOptions) {
  return this.each((_i, element) => {
    const options = defaults(pluginOptions, DEFAULTS);
    const $element = $(element);
    const $target = $($element.data('keypress-target'));

    const keyCodes = options.keycodes;

    const perform = ({ keyCode }) => {
      if (includes(keyCodes, keyCode)) {
        $target.click();
      }
    };

    $element.on('keypress', perform);
  });
}
