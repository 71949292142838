const { $ } = window;

function handleAjaxError(jqXHR) {
  alert(`An error occurred:\n${jqXHR.responseText}`);
}

export default function refreshCategoryCopyPoints() {
  this.each((_i, element) => {
    const $element = $(element);
    const $flyoutTarget = $(
      $element.data('copyPointTarget') || '#lead_copy_point_id'
    );
    const flyoutType = $element.data('flyoutType') || 'options';

    function copyPointAjaxUrl(categoryName) {
      const url = `/categories/${categoryName}/copy_points_by_group`;

      if (flyoutType === 'flyout') {
        return `${url}?as_flyout=true`;
      }

      if (flyoutType === 'json') {
        return `${url}.json`;
      }

      return url;
    }

    function updateCopyPoints(data) {
      $flyoutTarget.children().remove();
      $flyoutTarget.append(data);
      $flyoutTarget.trigger('change');
    }

    function addCopyPoints(categoryName) {
      $.get(copyPointAjaxUrl(categoryName))
        .success(updateCopyPoints)
        .error(handleAjaxError);
    }

    function onChange(e) {
      const $target = $(e.currentTarget);
      const categoryName = $target.val();
      if (categoryName === '') {
        updateCopyPoints('');
      } else {
        addCopyPoints(categoryName);
      }
    }

    $element.on('change', onChange);
  });
}
