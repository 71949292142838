const { $ } = window;

function checkPasswordMatch(base, confirmPassword) {
  let h;
  if (base.val() === confirmPassword.val() && base.val().length > 5) {
    h = '<i class="icon-ok icon-2x winning"></i>';
  } else {
    h = '<i class="icon-remove icon-2x losing"></i>';
  }
  return $('.password-status').html(h);
}

export default function passwordConfirmation() {
  return this.each((_i, element) => {
    const base = $(element);
    const confirmPassword = $(`#${base.attr('id')}_confirmation`);

    base.on('keyup', () => {
      if (base.val().length === 0) {
        $('.losing').hide();
      } else if (base.val().length < 6) {
        $('.winning').hide();
      }
    });

    confirmPassword.on('keyup', () => {
      if (base.val().length > 0) {
        checkPasswordMatch(base, confirmPassword);
      }
    });
  });
}
