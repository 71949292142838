const { $ } = window;

export default function leadSourceLeadForm() {
  return this.each((_i, element) => {
    const $sourceLeadForm = $(element);
    $sourceLeadForm.on('ajax:success', (evt, data) => {
      const $target = $($sourceLeadForm.data('target'));
      $target.children().remove();
      $target.append(data.html);
      return false;
    });

    $sourceLeadForm.on('click', '.export-leads', (evt) => {
      const $target = $(evt.currentTarget);
      if (!$target.data('original-href')) {
        $target.data('original-href', $target.attr('href'));
      }
      $target.attr(
        'href',
        `${$target.data('original-href')}&${$target
          .closest('form')
          .serialize()}`
      );
      return true;
    });

    $sourceLeadForm.submit();
  });
}
