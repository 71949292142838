const { $ } = window;

export default function showPhoneNumber() {
  return this.each((_i, element) => {
    const $element = $(element);
    if ($element.data('showPhoneNumber-loaded') === 'true') {
      return;
    }

    $element.data('showPhoneNumber-loaded', 'true');
    $element.on('click', (e) => {
      const sender = $(e.currentTarget);
      const target = $(`.phone-numbers-${sender.data('profile-id')}`);
      if (sender.data('action') === 'hide') {
        target.hide();
        sender.html(sender.attr('view_label'));
        sender.data('action', 'show');
      } else {
        target.show();
        sender.html(sender.attr('hide_label'));
        sender.data('action', 'hide');
      }
    });
  });
}
