import { merge } from 'lodash';

const { $ } = window;

export default function toggleButton(options) {
  const { stopDefault } = merge({ stopDefault: true }, options);

  this.each((_i, element) => {
    const $element = $(element);
    const groupName = $element.data('toggle-group');
    const toggleGroup = groupName !== undefined;
    const targ = $element.data('target');
    const $valueElement = targ != null ? $(targ) : $element;

    if ($element.hasClass('on') && !toggleGroup) {
      $element.val($element.data('value') || true);
    }

    function toggleSoloBehavior() {
      if ($element.hasClass('on')) {
        $element.removeClass('on').addClass('off');
      } else {
        $element.removeClass('off').addClass('on');
      }
    }

    function toggleGroupBehavior() {
      if (!$element.hasClass('on')) {
        $(`[data-toggle-group=${groupName}`).removeClass('on').addClass('off');
        $element.addClass('on').removeClass('off');
      }
    }

    function applyValues() {
      if ($element.hasClass('on')) {
        $valueElement.val($element.data('value') || true);
      } else {
        $valueElement.val('');
      }
    }

    function toggleButtonClick() {
      if (toggleGroup) {
        toggleGroupBehavior();
      } else {
        toggleSoloBehavior();
      }

      applyValues();
      return stopDefault !== true;
    }

    $element.on('click', toggleButtonClick);
  });
}
