export const isPresent = (value) => {
  if (value === undefined || value === null || value === '') return false;

  if (Array.isArray(value) && value.length === 0) return false;

  if (
    typeof value === 'object' &&
    value.constructor === Object &&
    Object.keys(value).length === 0
  )
    return false;

  if (typeof value === 'number' && Number.isNaN(value)) return false;

  if (typeof value === 'string' && value === '') return false;
  return true;
};

export const isBlank = (value) => !isPresent(value);

export const isFunction = (thing) => typeof thing === 'function';

export const isNotFunction = (thing) => !isFunction(thing);
