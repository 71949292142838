const { $, alert } = window;

export default function callFormValidator(acceptCategories, rejectCategories) {
  return this.each((_i, element) => {
    $(element).on('click', (evt) => {
      const callId = $(evt.currentTarget).data('callId');
      const $hiddenCourtesyCredit = $(`#hidden-check-box-${callId}`);
      const $courtesyCredit = $(`#${callId}-courtesy-credit`);
      const $callCategorySelect = $(`#${callId}-callcat`);
      const $accountNote = $(`#hidden-account-note-${callId}`);
      if ($hiddenCourtesyCredit.val() === 'true' && $accountNote.val() === '') {
        alert('You must add an account note!');
        return false;
      }

      if ($callCategorySelect.val() === '13') {
        const hiddenCourtesyCreditValue = $(
          `#hidden-correct-category-id-${callId}`
        ).val();
        if (!!hiddenCourtesyCreditValue === false) {
          alert('You must choose the correct category for this call!');
          return false;
        }
      }

      if (
        !(
          $courtesyCredit != null &&
          $courtesyCredit[0] != null &&
          $courtesyCredit[0].checked
        )
      ) {
        const $callVerificationSelect = $(`#${callId}-callverfid`);
        const acceptIds = acceptCategories;
        const rejectIds = rejectCategories;
        if ($callVerificationSelect.val() === '1') {
          if (rejectIds.includes(parseInt($callCategorySelect.val(), 10))) {
            alert(
              "You must choose a call category that matches the 'Accepted' verification state"
            );
            return false;
          }
        } else if ($callVerificationSelect.val() === '2') {
          if (acceptIds.includes(parseInt($callCategorySelect.val(), 10))) {
            alert(
              "You must choose a call category that matches the 'Rejected' verification state"
            );
            return false;
          }
        }
      }
      return true;
    });
  });
}
