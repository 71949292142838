import { union, forEach } from 'lodash';

const { $ } = window;

const LOGGING_ENABLED = window.location.href.match(/debug=form_nav/);

const log = (msg) => {
  if (LOGGING_ENABLED) {
    console.log(msg);
  }
};

class FormChangeTracker {
  constructor(element) {
    log('Initializing FormChangeTracker instance');
    this.disableTracking = this.disableTracking.bind(this);
    this.focus = this.focus.bind(this);

    this.element = $(element);
    this.keepTrackingChanges = true;
    this.dirtyElements = [];

    this.initForm();
  }

  isDirty() {
    return this.keepTrackingChanges && this.dirtyElements.length > 0;
  }

  initForm = () => {
    this.element.attr('data-preventDirtyFormEnabled', true);
    this.element.data('preventDirtyForm', this);

    // :input is a jquery pseudoclass that includes selects, buttons, textareas
    // http://api.jquery.com/input-selector/
    this.element
      .find(':input:not(:submit,:password,:button,:hidden)')
      .each((_index, element) => this.trackChangesToElement(element));

    this.element.on('submit', this.disableTracking);
  };

  handleChange = (event) => {
    if (!this.keepTrackingChanges) {
      log('Changes are not being tracked any longer');
      return;
    }

    const sender = $(event.currentTarget);
    const senderName = sender.attr('name');
    log(`Handling change event to ${senderName}`);

    const initialValue = sender.attr('data-initial');
    const dataValue =
      sender.attr('type') === 'checkbox'
        ? sender.prop('checked').toString()
        : sender.val();

    if (dataValue === initialValue) {
      log(
        `Data value ${dataValue} is same as initial value ${initialValue}, removing from dirty elements list`
      );
      this.dirtyElements = this.dirtyElements.filter((o) => o !== senderName);
    } else {
      log(
        `Data value ${dataValue} is different from initial value ${initialValue}, adding to dirty elements list`
      );
      this.dirtyElements = union(this.dirtyElements, [senderName]);
    }
    log(`Dirty elements list now at ${this.dirtyElements.length} elements`);
  };

  trackChangesToElement = (element) => {
    const input = $(element);
    log(`Adding tracking on element ${input.attr('name')}`);
    if (input.attr('type') === 'checkbox') {
      input.attr('data-initial', input.prop('checked').toString());
      input.change(this.handleChange);
    } else {
      input.attr('data-initial', input.val());
      input.blur(this.handleChange);
    }
  };

  focus = () => {
    if (this.dirtyElements.length > 0) {
      const dirtyElementName = this.dirtyElements[0];
      log(`Setting focus to ${dirtyElementName}`);
      this.element.find(`[name="${dirtyElementName}"]`).focus();
    } else {
      log('No dirty elements to set focus to');
    }
  };

  disableTracking() {
    log('Disabling tracking on form');
    this.keepTrackingChanges = false;
    this.dirty = false;
    this.dirtyElements = [];
  }
}

const dirtyMessage =
  'You have unsaved changes. If you leave, those changes will be lost.\n\nAre you sure you want to leave?';
const dirtyForms = [];

function hasDirtyForm() {
  const dirtyForm = dirtyForms.find(
    (form) => form.keepTrackingChanges && form.isDirty()
  );
  if (dirtyForm) {
    dirtyForm.focus();
    return true;
  }
  return false;
}

function navAlertWhenInvalid() {
  if (hasDirtyForm()) {
    return dirtyMessage;
  }
  if (navigator.userAgent.toLowerCase().indexOf('firefox') >= 0) {
    return '';
  }
  return undefined;
}

function clearDirtyForms() {
  forEach(dirtyForms, (v) => v.disableTracking());
}

function tabAlertWhenInvalid() {
  if (hasDirtyForm()) {
    if (confirm(dirtyMessage)) {
      clearDirtyForms();
      return true;
    }
    return false;
  }
  return true;
}

function setupAlerting() {
  if ($('body').attr('data-preventDirtyFormNav') !== 'true') {
    $('body').attr('data-preventDirtyFormNav', 'true');
    $(document).on('tabsbeforeactivate', tabAlertWhenInvalid);
    $(window).on('beforeunload', navAlertWhenInvalid);
  }
}

export default function preventDirtyFormNav() {
  setupAlerting();
  this.each((_i, element) => {
    dirtyForms.push(new FormChangeTracker(element));
  });
}
