const { $ } = window;

export default function toggleCheckBox() {
  return this.each((_i, element) => {
    $(element).on('click', (e) => {
      const $sender = $(e.currentTarget);
      const currentText = $sender.html();
      $sender.html($sender.data('alttext'));
      $sender.data('alttext', currentText);

      if ($sender.data('target') != null) {
        const $target = $($sender.data('target'));
        const isCurrentlyChecked =
          $target.filter(':checked').length === $target.length;
        $target.prop('checked', !isCurrentlyChecked);
      }
      return false;
    });
  });
}
