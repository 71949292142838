const { $ } = window;

export default function validateCopyPointFormat() {
  return this.each((_i, element) => {
    const $textbox = $(element);
    const $parent = $textbox.parents(
      '.profile_copy_point, .submission_copy_point'
    );
    const $checkbox = $parent.find(':checkbox');
    const formatRegexp = new RegExp(eval($textbox.data('format')));
    const errorMessage = $textbox.data('error');
    const label = $parent.find('label').text();

    const valid = () => formatRegexp.test($textbox.val());
    const addErrorMessage = () => {
      if (!$parent.find('.error_mesg')[0]) {
        $textbox.after(
          `<span class="error_mesg">&nbsp; ${label} ${errorMessage}</span>`
        );
        $textbox.addClass('point-error');
        $parent.find('.point-error').css('color', 'red');
      }
    };

    const clearErrorMessages = () => {
      if ($checkbox.prop('checked')) {
        $textbox.addClass('point-good').removeClass('point-error');
      } else {
        $textbox.removeClass('point-good').removeClass('point-error');
      }
      $parent.find('.error_mesg').remove();
      $parent.find('.point-good').css('color', 'gray');
    };

    const validate = () => {
      clearErrorMessages();
      if ($checkbox.prop('checked') && !valid()) {
        addErrorMessage();
        return false;
      }
      return true;
    };

    const onCheckBoxClick = () => {
      if ($checkbox.prop('checked')) {
        validate();
      } else {
        if ($textbox.val() == null) {
          $textbox.prop('disabled', true);
        }
        clearErrorMessages();
      }
      return true;
    };

    $checkbox.on('click', onCheckBoxClick);
    $textbox.on('blur', validate);
    validate();
  });
}
