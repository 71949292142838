const { $, location } = window;

export default function reloadPageOrShowError() {
  return this.each((_i, element) => {
    $(element).on('ajax:success', (event, xhr) => {
      if (xhr.status === 'ok') {
        location.reload();
      } else {
        $(event).parent().html(xhr.html);
      }
      return false;
    });
  });
}
