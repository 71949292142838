const { $ } = window;

export default function transmogrify(pluginOptions) {
  return this.each((_i, element) => {
    const $element = $(element);

    if ($element.data('transmogrify') === '1') {
      // Do not reload...
      return;
    }

    const settings = $.extend(
      {},
      {
        target: 'transmogrify-target',
        base: 'transmogrify-base',
        event: 'click',
        indexName: 'transmogrify-replace-child-index',
        replaceFunction: () => new Date().getTime()
      },
      pluginOptions || {}
    );

    const $target = $($element.data(settings.target));
    const $baseElement = $($element.data(settings.base));
    const $form = $element.parents('form');
    $form.on('submit', () => $baseElement.remove());

    const indexNameRegex = new RegExp($element.data(settings.indexName), 'g');
    const baseHtml = $baseElement.html();

    const triggerEvent = (type) => {
      $element.trigger({
        type: `transmogrify:${type}`,
        src: $element,
        target: $target,
        addedElement: $target.children().last()
      });
    };

    $element.data('transmogrify', '1');
    $baseElement.remove();

    $element.on(settings.event, () => {
      triggerEvent('before');
      const newItemHtml = baseHtml.replace(
        indexNameRegex,
        settings.replaceFunction()
      );
      const created = $target.append(newItemHtml).show();
      triggerEvent('after', created);
      return false;
    });
  });
}
