const { $, alert } = window;

export default function addLibraryBlock() {
  return this.each((_i, element) => {
    const $element = $(element);
    let libraryBlockJson = {};

    const closeModal = () => $element.closest('.modal').modal('hide');

    const addLibraryBlockData = (data) => {
      libraryBlockJson = data;
      $element.trigger('add_message_library_block');
      closeModal();
    };

    const replaceBlockValues = (evt) => {
      evt.addedElement
        .find('.library_block_body')
        .html(libraryBlockJson.block.body);
      evt.addedElement.find('.block_id').val(libraryBlockJson.block.id);
      evt.addedElement.find('.block_title').html(libraryBlockJson.block.title);
    };

    const insert = () => {
      const blockId = $('#library_block_id').val();
      if (blockId !== '') {
        $.get(
          $element.attr('href'),
          { block_id: blockId },
          addLibraryBlockData
        );
      } else {
        alert('Please select a library block!');
      }
      return false;
    };

    $element.on('click', insert);
    $element
      .transmogrify({
        event: 'add_message_library_block'
      })
      .on('transmogrify:after', replaceBlockValues);
  });
}
