/* eslint-disable no-param-reassign */

import addYextClickTrackerFromDetailsPage from 'jquery/yext';
import emphasize from 'jquery/emphasize';
import showPhoneNumber from 'jquery/show_phone_number';
import tabsByHash from 'jquery/tabs_by_hash';
import toggleCheckBox from 'jquery/toggleCheckBox';
import toggleDisplay from 'jquery/toggleDisplay';
import transmogrify from 'jquery/transmogrify';

import expandThenDisable from 'jquery/copy_points/expandThenDisable';
import validateCopyPointFormat from 'jquery/copy_points/validateCopyPointFormat';

import addLibraryBlock from 'jquery/admin/addLibraryBlock';
import checkInternalLeadAvailability from 'jquery/admin/check_internal_lead_availability';
import checkPipelineEligibility from 'jquery/admin/check_pipeline_eligibility';
import countyCitySearch from 'jquery/admin/countyCitySearch';
import clickOnKeyPress from 'jquery/admin/click_on_keypress';
import exportFromSearch from 'jquery/admin/exportFromSearch';
import filterSidebar from 'jquery/admin/filter_sidebar';
import leadSourceLeadForm from 'jquery/admin/leadSourceLeadForm';
import passwordConfirmation from 'jquery/admin/password_confirmation';
import preventDirtyFormNav from 'jquery/admin/accounts/prevent_dirty_form_navigation';
import profileBannerUploader from 'jquery/admin/profile_banner_uploader';
import profileLookupForIvrCampaign from 'jquery/admin/profile_lookup_for_ivr_campaign';
import quickPagination from 'jquery/admin/quickPagination';
import refreshCategoryCopyPoints from 'jquery/admin/refresh_category_copy_points';
import reloadPageOrShowError from 'jquery/admin/reloadPageOrShowError';
import resetErrorStylingOnKeypress from 'jquery/admin/reset_error_styling_on_keypress';
import sessionDates from 'jquery/admin/session_dates';
import toggleButton from 'jquery/admin/toggle_button';
import toggleTestimonialProfileCategoriesInfo from 'jquery/admin/testimonials';
import undoFieldsFor from 'jquery/admin/undo_fields_for';
import verifyActivityButton from 'jquery/admin/verify_activity_button';
import warmTransferFinder from 'jquery/admin/warm_transfer_finder';

// Admin Calls functions
import blacklistPhoneNumber from 'jquery/admin/calls/blacklistPhoneNumber';
import callFormValidator from 'jquery/admin/calls/callFormValidator';
import reloadCallRow from 'jquery/admin/calls/reloadCallRow';

const { $, console } = window;

((jQuery) => {
  if (!jQuery) {
    console.log('No jQuery object found so plugins cannot be added');
    return false;
  }

  // Shared Functions
  jQuery.fn.addYextClickTrackerFromDetailsPage =
    addYextClickTrackerFromDetailsPage;
  jQuery.fn.emphasize = emphasize;
  jQuery.fn.showPhoneNumber = showPhoneNumber;
  jQuery.fn.tabsByHash = tabsByHash;
  jQuery.fn.toggleCheckBox = toggleCheckBox;
  jQuery.fn.toggleDisplay = toggleDisplay;
  jQuery.fn.transmogrify = transmogrify;

  // Copy point functions
  jQuery.fn.expandThenDisable = expandThenDisable;
  jQuery.fn.validateCopyPointFormat = validateCopyPointFormat;

  // Admin functions
  jQuery.fn.addLibraryBlock = addLibraryBlock;
  jQuery.fn.checkInternalLeadAvailability = checkInternalLeadAvailability;
  jQuery.fn.checkPipelineEligibility = checkPipelineEligibility;
  jQuery.fn.clickOnKeyPress = clickOnKeyPress;
  jQuery.fn.countyCitySearch = countyCitySearch;
  jQuery.fn.exportFromSearch = exportFromSearch;
  jQuery.fn.filterSidebar = filterSidebar;
  jQuery.fn.leadSourceLeadForm = leadSourceLeadForm;
  jQuery.fn.passwordConfirmation = passwordConfirmation;
  jQuery.fn.preventDirtyFormNav = preventDirtyFormNav;
  jQuery.fn.profileBannerUploader = profileBannerUploader;
  jQuery.fn.profileLookupForIvrCampaign = profileLookupForIvrCampaign;
  jQuery.fn.quickPagination = quickPagination;
  jQuery.fn.refreshCategoryCopyPoints = refreshCategoryCopyPoints;
  jQuery.fn.reloadPageOrShowError = reloadPageOrShowError;
  jQuery.fn.resetErrorStylingOnKeypress = resetErrorStylingOnKeypress;
  jQuery.fn.sessionDates = sessionDates;
  jQuery.fn.toggleButton = toggleButton;
  jQuery.fn.toggleTestimonialProfileCategoriesInfo =
    toggleTestimonialProfileCategoriesInfo;
  jQuery.fn.undoFieldsFor = undoFieldsFor;
  jQuery.fn.verifyActivityButton = verifyActivityButton;
  jQuery.fn.warmTransferFinder = warmTransferFinder;

  // Admin Calls functions
  jQuery.fn.blacklistPhoneNumber = blacklistPhoneNumber;
  jQuery.fn.callFormValidator = callFormValidator;
  jQuery.fn.reloadCallRow = reloadCallRow;

  return true;
})($);
