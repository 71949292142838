const ID = '#search_state';
const URL = '/admin/accounts/geo_filter';

const { $ } = window;

export default function countyCitySearch() {
  return this.each(() => {
    const perform = () => {
      const params = {
        state: $(ID).val()
      };
      $.post(URL, params, (data) => $('#geography-fields').html(data));
    };

    $(document).on('change', ID, perform);
  });
}
