const { $, qq } = window;

export default function profileBannerUploader() {
  return this.each((i, element) => {
    let uploader;
    const $element = $(element);
    const bannerElem = () => $element.parents('.banner');
    const imageElem = () => bannerElem().find('.banner-image');
    const labelElem = () => bannerElem().find('.profile_banners_label');
    const idElement = () => bannerElem().find('.banner_id');
    const bannerId = () => idElement().val() || null;

    const showImage = (url) => imageElem().attr('src', url).show();

    const setBannerId = (bannerIdValue) => {
      uploader._options.params.banner_id = bannerIdValue; // eslint-disable-line no-underscore-dangle
      if (idElement() != null) {
        idElement().val(bannerIdValue);
      } else {
        const idId = labelElem().attr('id').replace(/label/, 'id');
        const idName = labelElem().attr('name').replace(/label/, 'name');
        $(
          `<input type='hidden' class='banner_id' name='${idName}' id='${idId} value='${bannerIdValue}' />`
        ).appendTo($element);
      }
    };

    const onComplete = (_id, _fileName, resp) => {
      if (resp.success) {
        showImage(resp.url);
        if (resp.banner_id !== bannerId()) {
          setBannerId(resp.banner_id);
        }
      }
      return true;
    };

    const onSubmit = () => {
      const p = {
        label: labelElem().val()
      };

      if (bannerId() != null) {
        p.banner_id = bannerId();
      }

      uploader.setParams(p);
      return true;
    };

    uploader = new qq.FileUploader({
      element: $element.find('.upload')[0],
      action: $element.data('upload-href'),
      multiple: false,
      onSubmit,
      onComplete
    });
  });
}
