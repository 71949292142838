import { merge, filter } from 'lodash';

const { $ } = window;

const DEFAULT_OPTIONS = {
  search_button: 'button[data-action=search]'
};

const LOGGING_ENABLED = window.location.href.match(/debug=filterSidebar/);
const log = (msg) => {
  if (LOGGING_ENABLED) {
    console.log(msg);
  }
};

export default function filterSidebar(options) {
  this.each((_i, element) => {
    const mergedOptions = merge(DEFAULT_OPTIONS, options);
    const $element = $(element);
    const searchButton = mergedOptions.search_button;

    function activeFilters() {
      return filter(
        $element.find('.searchParam:not([data-notify=false])'),
        (elem) => {
          const $elem = $(elem);
          const val = $elem.val();
          if (val === '' || val === null) {
            log(`${$elem.attr('name')} with value ${val} is inactive`);
            return false;
          }

          log(`${$elem.attr('name')} with value ${val} is active`);
          return true;
        }
      );
    }

    function anyFiltersActive() {
      log(`Have active filters of ${activeFilters().size}`);
      return activeFilters().length > 0;
    }

    function setFilterIndicator() {
      if (anyFiltersActive()) {
        $('.filter-callout').show('slow');
        $('.btn-filter-indicator').addClass('btn-warning darkorange');
      } else {
        $('.filter-callout').hide('slow');
        $('.btn-filter-indicator').removeClass('btn-warning darkorange');
      }
    }

    function setControlsToDefaults() {
      $element.find('.searchParam').val('').trigger('liszt:updated');
    }

    function search() {
      setFilterIndicator();
      $(searchButton).click();
    }

    function clear() {
      setControlsToDefaults();
      search();
    }

    $element.find('.btn-apply-advanced-filters').on('click', search);
    setFilterIndicator();
    $(document).on('click', '.btn-clear-advanced-filters', clear);
  });
}
