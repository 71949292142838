const { $ } = window;

function lookupEligibility(element) {
  const el = $(element);
  const url = el.data('url');

  el.data('original-url', url);

  if (url == null) {
    return;
  }
  $.get(el.data('url'), (response) => {
    el.data('url', null);
    el.html(
      response.sellable_level_id === 1
        ? '<i class="icon-ok-circle green" title="Sellable" />'
        : ''
    );
  });
}

export default function checkPipelineEligibility() {
  return this.each((_i, element) => lookupEligibility(element));
}
