const { $ } = window;

export default function toggleDisplay() {
  return this.each((_i, element) => {
    $(element).on('click', (e) => {
      const $sender = $(e.currentTarget);
      if ($sender.data('target') != null) {
        $($sender.data('target')).toggle();
      }
      const currentText = $sender.html();
      $sender.html($sender.attr('data-altText'));
      $sender.attr('data-altText', currentText);

      return $sender.prop('type') === 'checkbox';
    });
  });
}
