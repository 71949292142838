import { isBlank } from 'utils/boolean';

const { $ } = window;

const REQUIRED_FIELD_NAMES = ['category_id', 'zip_code'];

export default function warmTransferFinder() {
  this.each((_i, form) => {
    const $form = $(form);

    function clearOnChange() {
      $($form.data('target')).html('');
      return true;
    }

    function setSubmitButtonStatus() {
      const fieldEls = $form.serializeArray();
      const blankField = REQUIRED_FIELD_NAMES.some((requiredFieldName) => {
        const currentField = fieldEls.find(
          (fieldEl) => fieldEl.name === requiredFieldName
        );
        return isBlank(currentField) || isBlank(currentField.value);
      });

      const submitButton = $form.find('button');

      if (blankField) {
        submitButton.prop('disabled', true);
        submitButton.addClass('disabled');
      } else {
        submitButton.prop('disabled', false);
        submitButton.removeClass('disabled');
      }
    }

    $(document)
      .on('change input', '.required-field', setSubmitButtonStatus)
      .on('change', '#lead_copy_point_block', setSubmitButtonStatus);
    setSubmitButtonStatus();
    $form
      .find('.clear-on-change')
      .on('change', clearOnChange)
      .on('keydown', clearOnChange);
    $form
      .on('ajax:success', (_event, data) => {
        $($form.data('target')).html(data.html);
        return false;
      })
      .on('ajax:beforeSend', () => {
        const target = $($(this).data('loading-target'));
        target.children().remove();
        target.append('<span>Loading...</span>');
      });
  });
}
