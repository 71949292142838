import objectFromQueryString from 'utils/objectFromQueryString';

const { $, location } = window;

const clearHiddenData = (event, ui) => ui.oldPanel.empty();
const changeWindowHash = (event, ui) => {
  location.hash = `!/tab=${ui.tab.attr('name')}`;
};

export default function tabsByHash() {
  return this.each((_i, element) => {
    if ($.ui == null || $.ui.tabs == null) {
      return;
    }

    const $element = $(element);

    const indexOf = (param) => {
      let indexOfTab = -1;
      $element.find('ul > li').each((index, tab) => {
        const name = $(tab).attr('name');
        if (param === name) {
          indexOfTab = index;
        }
      });
      return indexOfTab;
    };

    const tabIdFromUrl = () => {
      const param = objectFromQueryString(
        window.location.hash.replace('#!/', '')
      ).tab;
      if (param != null) {
        return indexOf(param);
      }

      if ($element.data('defaultTabName') != null) {
        return indexOf($element.data('defaultTabName'));
      }

      return 0;
    };

    const switchToTabInUrl = () => {
      const activeTab = tabIdFromUrl();
      if (activeTab > 0) {
        $element.tabs('option', {
          active: activeTab
        });
      }
    };

    $element.tabs({
      beforeLoad: changeWindowHash,
      active: tabIdFromUrl(),
      activate: clearHiddenData
    });
    $(window).on('hashchange', switchToTabInUrl);
  });
}
