const { $ } = window;

export default function addYextClickTrackerFromDetailsPage() {
  return this.each((i, element) => {
    $(element).on('click', (event) => {
      const t = $(event.currentTarget);
      if (t.data('yext-id')) {
        const id = t.data('yext-id');
        const action = t.data('yext-action');
        const trackingPixel = $(
          `<img src="https://pl.yext.com/plpixel?pid=06d0b1f327&ids=${id}` +
            `&source=detailspage&action=click&target=${action}" style="display:none;" />`
        );
        $('body').append(trackingPixel);
      }
    });
  });
}
