import { defaults } from 'lodash';

const { $ } = window;
const DEFAULTS = {
  form: 'form',
  page: 'input#per_page',
  button: 'input[type=submit]'
};

export default function quickPagination(pluginOptions) {
  return this.each((_i, element) => {
    const { form, button, page } = defaults(pluginOptions, DEFAULTS);

    const $element = $(element);
    const $submit = $(form).find(button);
    const $page = $(page);
    const amount = $element.attr('data-amount');
    $element.on('click', () => {
      $page.val(amount);
      $submit.trigger('click');
    });
  });
}
