const { $ } = window;

function verifyActivityButtonClickFunction() {
  const self = $(this);
  const row = $(this).closest('tr');
  const status = self.hasClass('accept-button') ? 'accepted' : 'rejected';
  const oppositeStatus = self.hasClass('accept-button')
    ? 'rejected'
    : 'accepted';
  const action = status.substr(0, status.length - 2);
  const endpoint = self.attr('href');
  $.get(endpoint, (response) => {
    if (response === 'ok') {
      row
        .addClass(status)
        .removeClass(oppositeStatus)
        .removeClass('odd')
        .removeClass('even');
    } else {
      alert(`Something went wrong when attempting to ${action} this call.`);
    }
  });
  return false;
}

export default function verifyActivityButton() {
  return this.each((i, element) => {
    $(element).on('click', verifyActivityButtonClickFunction);
  });
}
