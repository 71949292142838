import { defaults } from 'lodash';

const { $ } = window;
const DEFAULTS = {
  form: null,
  item: '.line-item',
  submitText: 'Submit',
  disabledText: 'Disabled'
};

export default function undoFieldsFor(pluginOptions) {
  return this.each((_i, element) => {
    const options = defaults(pluginOptions, DEFAULTS);
    const $element = $(element);
    const $form = $(options.form);
    const $item = $element.closest(options.item);
    const $submit = $form.find('input[type="submit"]');

    const disableSubmission = () => {
      $submit.removeAttr('value');
      $submit.val(options.disabledText);
      $submit.addClass('inactive');
      $submit.attr('disabled', true);
      $submit.attr('data-text', $submit.val());
    };

    const enableSubmission = () => {
      $submit.removeAttr('value');
      $submit.val(options.submitText);
      $submit.removeClass('inactive');
      $submit.attr('disabled', false);
    };

    const hasLineItems = () => $form.find(options.item).length;

    const perform = () => {
      $item.hide();
      $item.find('input.destroy').val('1');
      if (!hasLineItems()) {
        disableSubmission();
      }
      return false;
    };

    $element.on('click', perform);
    enableSubmission();
  });
}
