const { $, alert } = window;

export default function reloadCallRow() {
  return this.each((_i, element) => {
    $(element).on('ajax:success', (event, xhr) => {
      if (xhr.html) {
        const row = $(this).closest('tr');
        row.replaceWith(xhr.html);

        if (xhr.header_html) {
          const header_row = $('.' + xhr.header_css);
          header_row.replaceWith(xhr.header_html);
        }
      } else {
        alert('Something went wrong when attempting to save this call!');
      }
    });
  });
}
