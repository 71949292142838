import { reduce, isArray, forEach, toPairs, keys } from 'lodash';

const { $, console } = window;

const DEFAULTS = {
  eventType: 'submit',
  searchParamSelector: '.searchParam',
  debug: false,
  perPage: false
};

const paramsAsHash = (selector) => {
  const iterator = (acc, elem) => {
    const rv = { ...acc };
    const $elem = $(elem);

    if ($elem.is(':checkbox') || $elem.is(':radio')) {
      if ($(`[name='${elem.name}']`).length > 1) {
        rv[elem.name] = isArray(rv[elem.name]) ? rv[elem.name] : [];
        $(`[name='${elem.name}']`).each(() => {
          if ($(this).prop('checked')) {
            console.log(`Found checked ${elem.name} with val ${$elem.val()}`);
            rv[elem.name].push($(this).val());
          }
        });
      } else {
        rv[elem.name] = $elem.prop('checked')
          ? $elem.val()
          : rv[elem.name] || '';
      }
    } else if ($elem.val() !== $elem.attr('placeholder')) {
      rv[elem.name] = $elem.val();
    }
    return rv;
  };
  return reduce($(selector), iterator, {});
};

export default function exportFromSearch(options) {
  const settings = $.extend(DEFAULTS, options);
  const log = (msg) => {
    if (settings.debug && typeof console !== 'undefined' && console !== null) {
      console.log(msg);
    }
  };

  return this.each((i, element) => {
    $(element).on(settings.eventType, (e) => {
      log(
        `Received ${settings.eventType} event for exporting.  Looking for parameters ${settings.searchParamSelector}`
      );
      const sender = $(e.currentTarget);
      const paramsHash = paramsAsHash(settings.searchParamSelector);
      log(`Processing params hash of ${toPairs(paramsHash)}`);
      forEach(keys(paramsHash), (param) => {
        let value = paramsHash[param];
        if (param === 'per_page' && settings.perPage) {
          value = settings.perPage;
        }
        log(`Adding hidden value of ${param} = ${value}`);
        sender.find(`input[name="${param}"]`).remove();
        sender.append(
          `<input type="hidden" name="${param}" value="${value}"/>`
        );
      });
    });
  });
}
