const { $, prompt, alert } = window;

export default function blacklistPhoneNumber() {
  return this.each((_i, element) => {
    $(element).on('click', (evt) => {
      const reason = prompt('Reason for blacklisting?');
      if (reason == null) {
        return false;
      }
      const $sender = $(evt.currentTarget);
      const phoneNumber = $sender.data('phone-number');
      $.post(
        evt.currentTarget.href,
        {
          blacklisted_phone_number: {
            phone_number: phoneNumber,
            notes: reason
          }
        },
        () => {
          $sender.removeClass('btn-warning');
          $sender.addClass('blacklisted-number');
          $sender.attr('disabled', true);
          alert('Number has been blacklisted.');
        }
      ).error((jqXHR) => {
        alert(`An error occurred:\n${jqXHR.responseText}`);
      });
      return false;
    });
  });
}
