const stringEndsWith = (str, suffix) =>
  str.indexOf(suffix, str.length - suffix.length) !== -1;

export default function objectFromQueryString(queryString) {
  if (queryString == null) {
    return {};
  }

  let str;
  if (queryString.indexOf('?') > -1) {
    [, str] = queryString.split('?');
  } else {
    str = queryString;
  }

  const obj = {};

  str.split('&').forEach((pairStr) => {
    if (pairStr.length === 0) {
      return;
    }

    const [key, value] = pairStr.split('=');

    if (stringEndsWith(key, '[]') || stringEndsWith(key, '%5B%5D')) {
      if (obj[key] == null) {
        obj[key] = [];
      }

      obj[key].push(value);
    } else {
      obj[key] = value;
    }
  });

  return obj;
}
